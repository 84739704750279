'use strict';

Gri.module({
  name: 'section-epsilon',
  ieVersion: null,
  $el: $('.section-epsilon'),
  container: '.section-epsilon',
  fn: function () {

    $('#scene').parallax();

  }
});
